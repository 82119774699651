import axios from "axios";


const BASE_URL_CF = process.env.VUE_APP_CF_D2C

export const zoneCosts = async (zipCode) => {
    try {
       const {data} = await axios.get(BASE_URL_CF + '/AIG_D2C_COSTCO_GET_ZONE?zipCode=' + zipCode)
       return data
    } catch (error) {
        throw error
    }
}

