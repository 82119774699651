<template>
  <div>
    <!-- <h3 class="text-center font-weight-normal mb-5">Selección de Plan</h3> -->
    <form class="text-center">
      <div class="row mt-4">
        <div class="col-md-6">
          <h4 class="font-weight-light">Tipo de Seguro</h4>
          <h4 class="font-weight-bold text-primary">
            {{ query.selectedPackage }}
          </h4>
        </div>
        <div class="col-md-6">
          <h4 class="font-weight-light">Código postal</h4>
          <h4 class="font-weight-bold text-primary">
            {{ query.postalCode }}
          </h4>
        </div>
      </div>

      <hr />
      <div class="text-center row">
        <div class="container text-justify">
          <PlansV3 :zipCode="query.postalCode" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import PlansV3 from "@/components/plans/PlansV3";

export default {
  components: {
    PlansV3,
  },

  computed: {
    query() {
      return this.$route.query;
    },
  },
};
</script>